<template>
  <div>
    <div
      v-for="item in kurlar"
      :key="item.kod"
      class="transaction-item d-flex justify-content-between p-1"
    >
      <b-media no-body>
        <b-media-aside>
          <b-avatar rounded size="42" variant="primary">
            {{ item.kod }}
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="transaction-title">
            {{ item.banka_kodu }}
          </h6>
          <small>{{ item.birim }}</small>
        </b-media-body>
      </b-media>
      <div class="font-weight-bolder text-danger">
        {{ $t('kurlar.alis') }} {{ item.alis | cur }} ₺
      </div>
      <div class="font-weight-bolder text-success">
        {{ $t('kurlar.satis') }} {{ item.satis | cur }} ₺
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    kurlar: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
