<template>
  <div>
    <b-row>
      <b-col cols="12" md="8">
        <b-card-actions
          ref="cardAction"
          :title="$t('menu.kurlar')"
          icon="DollarSignIcon"
          :actionCollapse="false"
          :actionRefresh="true"
          :actionNewAdd="true"
          :collapsed="true"
          :showLoading="show"
          @refresh="refreshStop($event)"
          @newAdd="handlerEkle($event)"
        >
          <template v-slot:body>
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :line-numbers="true"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              styleClass="vgt-table striped bordered"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'firma_adi'">
                  <router-link
                    :to="{
                      name: 'firma-islemler',
                      params: { id: props.row._id },
                    }"
                  >
                    {{ props.row.firma_adi }}
                  </router-link>
                </span>
                <span v-else-if="props.column.field === 'tarih'">
                  {{ props.row.tarih | momentFull }}
                </span>
                <span v-else-if="props.column.field === 'subeler'">
                  <b-badge :variant="props.row.firmaSubeler > 0 ? 'light-success' : 'light-danger'" class="d-block m-1">
                    {{
                      props.row.firmaSubeler > 0
                        ? `${$t('firmalar.toplamSube')}  ${props.row.firmaSubeler}`
                        : `${$t('firmalar.tanimsizSube')}`
                    }}
                  </b-badge>
                </span>
                <span
                  v-else-if="props.column.field === 'bakiye'"
                  :class="props.row.bakiye > 0 ? 'text-success' : props.row.bakiye == 0 ? 'text-secondary' : 'text-danger'"
                >
                  {{ props.row.bakiye | cur }}
                  {{ props.row.bakiye > 0 ? 'A' : props.row.bakiye == 0 ? '' : 'B' }}
                </span>
                <span v-else-if="props.column.field === 'action'" class="float-right">
                  <feather-icon
                    icon="EditIcon"
                    size="18"
                    class="mr-1 text-warning"
                    v-b-tooltip.hover.top="$t('global.duzenle')"
                    @click="handlerDuzenle(props.row)"
                  />
                  <feather-icon
                    icon="Trash2Icon"
                    size="18"
                    class="mr-1 text-danger"
                    v-b-tooltip.hover.top="$t('global.sil')"
                    @click="handlerSil(props.row._id)"
                  />
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <v-select
                      v-model="pageLength"
                      :options="perPageOptions"
                      :clearable="false"
                      class="invoice-filter-select"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </template>
        </b-card-actions>
      </b-col>
      <b-col cols="12" md="4">
        <b-card-actions
          ref="cardAction"
          :title="$t('kurlar.anlikKurlar')"
          icon="DollarSignIcon"
          :actionCollapse="false"
          :actionRefresh="true"
          :actionNewAdd="false"
          :collapsed="true"
          :showLoading="show"
          @refresh="refreshKurStop($event)"
        >
          <template v-slot:body>
            <anlik-kur-listesi :kurlar="anlikKurListesi" />
          </template>
        </b-card-actions>
      </b-col>
    </b-row>

    <kur-turu-ekle v-model="openModal" :openModal="openModal" :updateData="updateData" @closeModal="closeModal = $event" />
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import { VueGoodTable } from 'vue-good-table';
import vSelect from 'vue-select';
import KurTuruEkle from './kur_turu_ekle.vue';
import AnlikKurListesi from './anlik-kur-listesi.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
  components: {
    BCardActions,
    KurTuruEkle,
    AnlikKurListesi,
    VueGoodTable,
    vSelect,
  },
  data() {
    return {
      show: false,
      closeModal: false,
      updateData: {},
      openModal: false,
      pageLength: 10,
      perPageOptions: [10, 25, 50, 100],
      kurlar: [],
      columns: [
        {
          label: this.$t('kurlar.birim'),
          field: 'birim',
        },
        {
          label: this.$t('kurlar.kod'),
          field: 'kod',
        },
        {
          label: this.$t('kurlar.bankaKodu'),
          field: 'banka_kodu',
        },
        {
          label: this.$t('global.islemler'),
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    };
  },
  created() {
    this.handlerGetData();
    this.handlerKurAnlik();
  },
  computed: {
    anlikKurListesi() {
      return this.$store.getters.getAnlikKurListesi;
    },
  },
  methods: {
    async handlerGetData() {
      this.show = true;
      await this.$store.dispatch('kurTurleriListele');
      this.rows = await this.$store.getters.getKurTurleri;
      this.show = false;
    },

    async handlerKurAnlik() {
      this.show = true;
      if (navigator.onLine) {
        await this.$store.dispatch('anlikKurListesi');
      } else {
        console.log('Unable to connect to internet.');
      }
      this.show = false;
    },

    refreshStop(event) {
      this.handlerGetData();
    },

    refreshKurStop() {
      this.handlerKurAnlik();
    },

    handlerEkle(event) {
      this.openModal = true;
    },
    handlerIncele(event) {},
    handlerDuzenle(task) {
      if (task) {
        this.updateData = task;
        this.openModal = true;
      }
    },
    handlerSil(id) {
      this.$swal({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.$store.dispatch('kurTurleriSil', id).then((res) => {
            if (res.data.success === true) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Silme`,
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: `Kayit silme başarılı.`,
                },
              });
            }
          });
        }
      });
    },
  },
  watch: {
    async closeModal(val) {
      if (val == false) {
        this.openModal = false;
        this.updateData = {};
        this.rows = [];
        this.rows = await this.$store.getters.getKurTurleri;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba($black, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
