<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="openModal"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('closeModal', val)"
      @hidden="resetForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2">
          <h5 class="mb-0">
            Kur Türü Ekle
          </h5>
          <div>
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
        </div>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" class="p-2" autocomplete="off">
            <b-row>
              <b-col>
                <validation-provider name="Birim" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Birim (TL)" label-for="birim">
                    <b-form-input
                      class="rounded-0"
                      id="birim"
                      name="birim"
                      ref="birim"
                      v-model="form.birim"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <validation-provider name="Kod" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Kod (₺)" label-for="kod">
                    <b-form-input
                      class="rounded-0"
                      id="kod"
                      name="kod"
                      ref="kod"
                      v-model="form.kod"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <validation-provider name="Banka Kodu" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Banka Kodu (TRY)" label-for="banka_kodu">
                    <b-form-input
                      class="rounded-0"
                      id="banka_kodu"
                      name="banka_kodu"
                      ref="banka_kodu"
                      v-model="form.banka_kodu"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback">{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-button squared type="submit" variant="primary" :disabled="isLoading">
              <b-spinner small class="mr-1" v-show="isLoading" />
              <feather-icon icon="CheckSquareIcon" class="mr-50" />
              <span class="align-middle">
                {{ form.id == null ? 'Ekle' : 'Güncelle' }}
              </span>
            </b-button>
            <b-button squared class="ml-2" @click="hide">
              <feather-icon icon="XSquareIcon" class="mr-50" />
              <span class="align-middle">İptal</span>
            </b-button>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      form: {
        id: null,
        firma_k_no: 'asdfcbreetr',
        birim: null,
        kod: null,
        banka_kodu: null,
      },
      savedForm: false,
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.form = {
        _id: null,
        birim: null,
        kod: null,
        banka_kodu: null,
      };
      this.savedForm = false;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
      this.$refs.birim.focus();
    },
    onSubmit() {
      this.savedForm = true;
      if (this.form.id == null) {
        this.$store.dispatch('kurTurleriEkle', this.form).then((res) => {
          if (res.data.success === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Ekleme`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: `Ekleme başarılı.`,
              },
            });
            this.resetForm();
          }
        });
      } else {
        this.$store.dispatch('kurTurleriGuncelle', this.form).then((res) => {
          if (res.data.success === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Güncelleme`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: `Güncelleme başarılı.`,
              },
            });
            this.$emit('closeModal', false);
          }
        });
      }
    },
  },
  computed: {
    isLoading() {
      return this.savedForm == true ? true : false;
    },
  },
  watch: {
    updateData(val) {
      if (val != null) {
        this.form.id = val._id;
        this.form.birim = val.birim;
        this.form.kod = val.kod;
        this.form.banka_kodu = val.banka_kodu;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
